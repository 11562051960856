'use strict';

$.extend($.easing, {
    easeOutExpo: function (x, t, b, c, d) {
        return (t == d) ? b + c : c * (-Math.pow(2, -10 * t / d) + 1) + b;
    },
    easeInOutExpo: function (x, t, b, c, d) {
		if (t==0) return b;
		if (t==d) return b+c;
		if ((t/=d/2) < 1) return c/2 * Math.pow(2, 10 * (t - 1)) + b;
		return c/2 * (-Math.pow(2, -10 * --t) + 2) + b;
	}
});


$(document).on('click auxclick contextmenu', '[data-ga360-click]',  function(e) {
    console.log(e.which);
    //console.log(e.type);

    if(e.type=="contextmenu"){return;}

    var T = $(this).data('ga360-click');

        switch(e.which) {
            case 1:
                //console.log('[data-ga360-click] = click');
                WP.pwp.customAction({"ga": T });
                break;
            case 2:
                //console.log('[data-ga360-click] = weel click');
                WP.pwp.customAction({"ga": T });
                break;
            case 3:
                return true;
        }
});

//Obiekt zbierający informacje o elementach typu banner, slider, before/after, których statystyki należy wysłać w unload
var statElementCollector = new Object();
var ga360statElementsInit = function(el) {
    //alert( $(el).data('ga360-id') );
};

var slidePositionVieved = new Object();
//owlstats ga360 slider stats slider
var owl = $(".owl-carousel").each(function(){
    //sprawdzamy, czy jest ustawiony id dla statystyk
    var ga360id = $(this).data('ga360-id');
    //alert(ga360id);
    if (ga360id == undefined) {
        console.log('%c dodać ga360-id do slidera #' + $(this).data('gallery'), 'background: #553311; padding: 3px; color: #ffaaaa');
    } else {
      statElementCollector[ga360id] = {
        id: ga360id,
        moduleName: $(this).data('gallery'),
        viewedCount: 0,  
        itemCount:  $(this).find('.owl-item:not(.cloned)').length
      };  
    };
    $(this).statVievedSlide = [];
    //pozycje
    $(this).find('.owl-item:not(.cloned)').each(function(n){
        $(this).attr('idx',  n + 1);
    });
    slidePositionVieved[ga360id] = {};
});


function ga360Test(){

    var statElementArray = [];
    
    for (var pos in statElementCollector) {
        statElementArray.push(statElementCollector[pos]);
    };

    console.log(statElementArray);

}

owl.parent().find('.owl-next, .owl-prev').on("click", function(event) {
    
    var $owl = $(this).parents('.owl-carousel');
    var $arId = $(this).parents('.__js_stats_wrapper').data('id');
    var $gId = $(this).parents('.owl-carousel').data('gallery');
    var $acId = $owl.find('.owl-item.center .owl-img>img').attr('src');
    //ga360
    var ga360id = $(this).parent().parent().find('.owl-carousel').data('ga360-id');
    var activeItem = $(this).parent().parent().find('.owl-item.center').attr('idx');
    //alert(activeItem + '' + ga360id);
    
    slidePositionVieved[ ga360id ][ activeItem ] = 1;
    statElementCollector[ga360id]['viewedCount'] = Object.keys(slidePositionVieved[ ga360id ]).length;
    
    sendStats("gallery", "click", $arId + " / " + $gId, $acId);
    WP.pwp.customAction({"ga": {"event": "content_interaction","eventCategory":ga360id, "moduleName": $gId ,"eventAction": "click"}});
});


owl.on("dragged.owl.carousel", function(event) {
    var $arId = $(this).parents('article').data('id');
    var $gId = $(this).data('gallery');
    var ga360id = $(this).data('ga360-id');
    var $item = $('.owl-item');
    var $img = $item.find('img');
    var $acId = $img.eq(event.item.index).attr('src');
    sendStats("gallery", "swipe", $arId + " / " + $gId, $acId);
    WP.pwp.customAction({"ga": {"event": "content_interaction","eventCategory":ga360id, "moduleName": $gId,"eventAction": "swipe"}});
});

var viewData = [];


// flaga dla urządzeń z touch:
if ('ontouchstart' in document.documentElement) {
  var deviceTouch = true;
}

// flaga dla scrollowania:
var isScrolligFlag = false;
var idTS;

window.addEventListener('scroll', function(){
	isScrolligFlag = true;
  if (idTS) {
    clearTimeout(idTS);
  }
  idTS = setTimeout(function(){
  	idTS = null;
  	isScrolligFlag = false;
  }, 450);
});
